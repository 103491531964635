import { Flex, Button as PomodoroButton } from '@ifood/pomodoro-components';
import styled from 'styled-components';

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => theme.space.regular};
`;

export const Button = styled(PomodoroButton)`
  width: 100%;
  margin-top: ${({ theme }) => theme.space.larger};

  @media (min-width: 750px) {
    width: unset;
  }
`;
