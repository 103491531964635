import { accountAboyeur } from '@whitelabel-webapp/account/shared/config';
import { useAuthentication } from '@whitelabel-webapp/authentication/shared/authentication-store';
import { Heading, Text } from '@whitelabel-webapp/shared/design-system';
import NextImage from 'next/image';
import { useEffect } from 'react';

import * as S from './styles';

export const EmptyState: React.VFC = () => {
  const { openIfoodAuthentication, closeIfoodAuthentication } = useAuthentication();

  useEffect(() => {
    accountAboyeur.events.profile.emptyState();

    return function unmount() {
      closeIfoodAuthentication();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSignInOrSignUp() {
    openIfoodAuthentication('EMAIL', 'profile');
    accountAboyeur.events.profile.login();
  }

  return (
    <S.Container>
      <NextImage src="/images/account/empty-state.svg" alt="" width={220} height={110} layout="fixed" />
      <Heading as="h2" textAlign="center" variant="small" mt={40}>
        Itens incríveis te esperam
      </Heading>
      <Text textAlign="center">Entre ou cadastre-se pra aproveitar ao máximo nosso cardápio</Text>
      {/*eslint-disable-next-line @typescript-eslint/no-empty-function*/}
      <S.Button variant="primary" onClick={handleSignInOrSignUp}>
        Entrar ou cadastrar-se
      </S.Button>
    </S.Container>
  );
};
