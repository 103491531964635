import { useAccount } from "@whitelabel-webapp/account/shared/account-store";
import { accountAboyeur } from "@whitelabel-webapp/account/shared/config";
import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import { useAuthentication } from "@whitelabel-webapp/authentication/shared/authentication-store";
import { Z_INDEXES } from "@whitelabel-webapp/shared/constants";
import {
  DrawerWithPush,
  Exit,
  Heading,
  Order,
  PinOutlined,
} from "@whitelabel-webapp/shared/design-system";
import NextImage from "next/image";
import { useEffect } from "react";

import { EmptyState } from "../EmptyState";
import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";
import { useRouter } from "next/router";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

export const Profile: React.VFC = () => {
  const router = useRouter();
  const { merchant } = useMerchant();
  const { isProfileDrawerOpen, closeProfileDrawer } = useAccount();
  const { customer, logout } = useAuthentication();
  const { addressConfig, openAddress, closeAddress } = useAddress();

  useEffect(() => {
    if (isProfileDrawerOpen || !addressConfig.isOpen) return;

    closeAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileDrawerOpen]);

  function handleAddresses() {
    closeProfileDrawer();
    setTimeout(() => openAddress("MERCHANT"), 0);
    accountAboyeur.events.profile.addresses();
  }

  function handleOrders() {
    closeProfileDrawer();
    router.push(`/${merchant.query}/order`);
    accountAboyeur.events.profile.orders();
  }

  function handleLogout() {
    logout();
    closeProfileDrawer();
    accountAboyeur.events.profile.logout();
  }

  if (!isProfileDrawerOpen) return null;

  return (
    <DrawerWithPush
      open={true}
      onClose={closeProfileDrawer}
      zIndex={Z_INDEXES.PROFILE_DRAWER}
    >
      {customer && (
        <Flex flexDirection="column">
          <S.Header>
            <NextImage
              src="/images/account/profile-image.svg"
              width={38}
              height={38}
            />
            <Heading>Olá, {customer?.user.name}</Heading>
          </S.Header>
          <Flex flexDirection="column">
            <S.Option onClick={handleAddresses}>
              <S.Icon component={PinOutlined} size="s" />
              <S.Text>Endereços</S.Text>
            </S.Option>
            <S.Option onClick={handleOrders}>
              <S.Icon component={Order} size="s" />
              <S.Text>Pedidos</S.Text>
            </S.Option>
            <S.Option onClick={handleLogout}>
              <S.Icon component={Exit} size="s" />
              <S.Text>Sair</S.Text>
            </S.Option>
          </Flex>
        </Flex>
      )}
      {!customer && <EmptyState />}
    </DrawerWithPush>
  );
};
