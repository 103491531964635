import {
  Flex,
  BaseDrawer as PomodoroBaseDrawer,
  Icon as PomodoroIcon,
  Text as PomodoroText,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const BaseDrawer = styled(PomodoroBaseDrawer)``;

export const Header = styled(PomodoroBaseDrawer.Header)`
  display: flex;
  gap: ${({ theme }) => theme.space.regular};
  padding: 0;
  margin-bottom: ${({ theme }) => theme.space.regular};
  margin-top: -${({ theme }) => theme.space.regular};

  h1 {
    font-size: 20px;
    margin: auto 0px;
  }
`;

export const Option = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.grayLight};
  padding: ${({ theme }) => theme.space.regular} 0;
  gap: ${({ theme }) => theme.space.regular};
  cursor: pointer;
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Text = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  color: ${({ theme }) => theme.colors.grayDarkest};
`;
